import axios from 'axios'

export const consultaReceita = async (cnpj) => {
    try {
        let result = await axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`)
        return result
    } catch (error) {
        console.log(error)
        return error
    }
}
